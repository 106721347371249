/*
	SASS variables, functions, mixins, etc. This can be imported in individual component styles. No CSS should be
	generated in webpack by importing this file.
 */

@use 'sass:math';
@use "sass:meta";



/* Functions */

/* Calculate rem unit based on browser default 16px font-size. */
@function px-to-rem($pxValue) {
	@return math.div($pxValue, 16px) * 1rem;
}



/* Mixins */

// This mixin provides styles for a container that houses a standard page so that the elements are spaced with the most
//  common usage. Pass `clipped` to limit the maximum width.
//  @deprecated use layout region in styles.scss. You can apply these styles on a component's host with:
//    host: { 'class': 'content-gutter clipped vertical-content' }
@mixin page-content($clipped: false) {
	display: flex;
	flex-direction: column;
	gap: px-to-rem(40px);

	@if $clipped == true {
		@media (min-width: $breakpoint-745) {
			max-width: px-to-rem(1063px);
		}
	}

	padding: 0 16px 80px; // todo the top/bottom margins are different in the two 430-320 mockups, verify this is correct

	@media (min-width: $breakpoint-431) {
		padding: 0 32px 80px;
	}

	@media (min-width: $breakpoint-745) {
		padding: 0 48px 80px;
	}

	@media (min-width: $breakpoint-835) {
		padding: 0 64px 80px;
	}

	@media (min-width: $breakpoint-1201) {
		padding: 0 80px 80px;
	}
}

// @include repeat-value(padding, 99px, [left, right]);
//@mixin repeat-value($prop, $value, $extended: []) {
//	@each $ext in $extended {
//		#{$prop}-#{$ext}: $value;
//	}
//}

/**
 * Generate breakpoint
 * $breakpoint
 * $type: above | below = above - Translates to min-width and max-width
 */
@mixin breakpoint($breakpoint, $type: above) {
	$prop: 'min-width';
	@if ($type == below) {
		$prop: 'max-width';
	}
	@media screen and (#{$prop}: $breakpoint) {
		@content;
	}
}

/*
	Breakpoints
	@media queries cannot use CSS vars because they are declared at the root of the document, but media queries are
	above the document. See: https://bholmes.dev/blog/alternative-to-css-variable-media-queries/
  */
// no mobile breakpoint with mobile-first design
// todo do we need a (max-width: 329)? if so what do we show?
$breakpoint-431: px-to-rem(431px);
$breakpoint-745: px-to-rem(745px); // first "desktop" breakpoint
$breakpoint-835: px-to-rem(835px);
$breakpoint-1201: px-to-rem(1201px);
$breakpoint-1601: px-to-rem(1601px);
$breakpoint-2001: px-to-rem(2001px);

// sub-breakpoints
$breakpoint-372: px-to-rem(372px);
$breakpoint-521: px-to-rem(521px);
$breakpoint-555: px-to-rem(555px);
$breakpoint-576: px-to-rem(576px);
$breakpoint-981: px-to-rem(981px);
$breakpoint-990: px-to-rem(990px);
$breakpoint-1121: px-to-rem(1121px);


/* Colors */
$color-base-black: #181E24;
$color-base-gray-200: #626D78;
$color-base-gray-300: #7F8B97;
$color-base-gray-400: #A0A9B2;
$color-base-gray-500: #C1C7CD;
$color-base-gray-800: #DFE1E3;
$color-base-gray-900: #E9EBED;
$color-base-gray-1000: #F4F5F6;
$color-base-white: #FFFFFF;

$color-accent-primary-dark: #2B2E71;
$color-accent-primary-default: #0167C5;
$color-accent-primary-light: #3B8EDE;
$color-accent-primary-800: #ACC9E8;
$color-accent-primary-900: #D7E5F4;
$color-accent-primary-1000: #E8EEF5;
$color-accent-secondary-medium: #44883E;

$color-accent-secondary-dark: #084C02;
$color-accent-secondary-medium: #44883E;
$color-accent-secondary-light: #58B250;
$color-accent-secondary-1000: #E7F5E6;

$color-tint-destructive: #D51010;
$color-tint-destructive-300: #EE6868;
$color-tint-destructive-1000: #F9EBEC;
$color-tint-success: #3D7F0B;
$color-tint-success-300: #85B621;
$color-tint-success-600: #A3D43F;
$color-tint-success-800: #CBE77C;
$color-tint-success-1000: #F2F7E4;
$color-tint-caution: #DB7200;
$color-tint-caution-300: #F79726;
$color-tint-caution-600: #EFC86D;
$color-tint-caution-800: #F6E2B1;
$color-tint-caution-1000: #FDF4E3;
$color-tint-purple: #7C4AA7;
$color-tint-purple-1000: #F1E3FD;
$shadow-color: rgba(35, 38, 42, 0.2);
