/* You can add global styles to this file, and also import other style files */
@import "variables";
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'overlayscrollbars/styles/overlayscrollbars.css';


:root {
	--default-transition-duration: 0.6s;
	--default-transition-timing-function: ease-out;
	--default-transition: var(--default-transition-duration, --default-transition-timing-function);

	--color-base-black: #{$color-base-black};
	--color-base-gray-200: #{$color-base-gray-200};
	--color-base-gray-300: #{$color-base-gray-300};
	--color-base-gray-400: #{$color-base-gray-400};
	--color-base-gray-500: #{$color-base-gray-500};
	--color-base-gray-800: #{$color-base-gray-800};
	--color-base-gray-900: #{$color-base-gray-900};
	--color-base-gray-1000: #{$color-base-gray-1000};
	--color-base-white: #{$color-base-white};

	--color-accent-primary-dark: #{$color-accent-primary-dark};
	--color-accent-primary-default: #{$color-accent-primary-default};
	--color-accent-primary-light: #{$color-accent-primary-light};
	--color-accent-primary-800: #{$color-accent-primary-800};
	--color-accent-primary-900: #{$color-accent-primary-900};
	--color-accent-primary-1000: #{$color-accent-primary-1000};

	--color-accent-secondary-dark: #{$color-accent-secondary-dark};
	--color-accent-secondary-medium: #{$color-accent-secondary-medium};
	--color-accent-secondary-light: #{$color-accent-secondary-light};
	--color-accent-secondary-1000: #{$color-accent-secondary-1000};

	--color-tint-destructive: #{$color-tint-destructive};
	--color-tint-destructive-300: #{$color-tint-destructive-300};
	--color-tint-destructive-1000: #{$color-tint-destructive-1000};
	--color-tint-success: #{$color-tint-success};
	--color-tint-success-300: #{$color-tint-success-300};
	--color-tint-success-600: #{$color-tint-success-600};
	--color-tint-success-800: #{$color-tint-success-800};
	--color-tint-success-1000: #{$color-tint-success-1000};
	--color-tint-caution: #{$color-tint-caution};
	--color-tint-caution-300: #{$color-tint-caution-300};
	--color-tint-caution-600: #{$color-tint-caution-600};
	--color-tint-caution-800: #{$color-tint-caution-800};
	--color-tint-caution-1000: #{$color-tint-caution-1000};
	--color-tint-purple: #{$color-tint-purple};
	--color-tint-purple-1000: #{$color-tint-purple-1000};

	--color-overlay-1000: rgba(27, 33, 39, 0.04);
	--color-overlay-500: rgba(27, 33, 39, 0.18);

	--box-shadow-medium: 0 8px 24px #{$shadow-color};
	--box-shadow-big: 0 16px 48px #{$shadow-color};
}

html, body {
	margin: 0;
	padding: 0;
}

body {
	background-color: var(--color-white);
	color: var(--color-base-black);
	font-family: neue-haas-grotesk-text, sans-serif;
	//font-family: neue-haas-grotesk-display, sans-serif;
	font-weight: 400;
	line-height: 1.3;
	height: 100%;
	width: 100%;
	position: fixed;
}

/* Typography */
h1, h2, h3, h4, h5, h6, p {
	margin: 0;
}

h1 {
	font-size: px-to-rem(34px);
	line-height: 1.1;
}

// Deviation from mockups (smaller font-size) to better accommodate longer names and page titles
h2 {
	font-size: px-to-rem(28px);
	line-height: 1.1;
}

// Deviation from mockups (smaller font-size) to better accommodate longer names and page titles
h3 {
	font-size: px-to-rem(24px);
	line-height: 1.2;
}

h4 {
	font-size: px-to-rem(20px);
}

h5 {
	font-size: px-to-rem(17px);
}

h6 {
	font-size: px-to-rem(14px);
}

.h7 {
	font-size: px-to-rem(13px);
}

.small-text {
	font-size: px-to-rem(14px);
}

.x-small-text {
	font-size: px-to-rem(12px);
	font-weight: 400;
}

a {
	font-weight: 500;
	color: var(--color-accent-primary-default);
	transition: color var(--default-transition);

	&:hover {
		color: var(--color-accent-primary-light);
	}

	&:active, &.active {
		color: var(--color-accent-primary-dark);
	}
}

a.small-text {
	font-size: px-to-rem(14px);
}

button {
	font-size: px-to-rem(16px);
	font-weight: 500;
}

// normalize button text-size
button.small-text {
}

// inherits button.font-weight and .small.font-size
button.x-small-text {
	font-weight: 700;
}

// inherits .x-small.size
// Deviation from mockups to better accommodate longer names and page titles
@media (min-width: $breakpoint-431) {
	h1 {
		font-size: px-to-rem(40px);
	}
	h2 {
		font-size: px-to-rem(30px);
	}
}

// larger typography for "desktop"
@media (min-width: $breakpoint-745) {
	h1 {
		font-size: px-to-rem(48px);
	}
	h2 {
		font-size: px-to-rem(36px);
	}
	h3 {
		font-size: px-to-rem(30px);
	}
	h4 {
		font-size: px-to-rem(22px);
	}
	h5 {
		font-size: px-to-rem(18px);
	}
	h6 {
		font-size: px-to-rem(16px);
	}
	.h7 {
		font-size: px-to-rem(14px);
	}
}

/* Generic element styles */
button, .button {
	box-sizing: border-box;
	border: none;
	cursor: pointer;
	transition: color var(--default-transition),
	background-color var(--default-transition);
	display: inline-flex;
	align-items: center;
	gap: px-to-rem(8px);
	background-color: transparent;
	padding: 0;
	//width: fit-content; // this is bad, talk to Micah before you re-enable it

	// override for anchor tags wrapped in button styling
	text-decoration: none;

	&.solid, &.outline {
		// always small on mobile
		font-size: px-to-rem(14px);
		border-radius: px-to-rem(6px);
		padding: 0 px-to-rem(10px);
		min-height: px-to-rem(24px);

		@media (min-width: $breakpoint-745) {
			&.small {
				font-size: px-to-rem(14px);
				border-radius: px-to-rem(6px);
				padding: 0 px-to-rem(10px);
				min-height: px-to-rem(24px);
			}

			&, &.medium {
				font-size: px-to-rem(16px);
				border-radius: px-to-rem(8px);
				padding: 0 px-to-rem(12px);
				min-height: px-to-rem(32px);
			}

			&.large {
				border-radius: px-to-rem(10px);
				padding: 0 px-to-rem(20px);
				min-height: px-to-rem(44px);
			}
		}
	}

	&.solid {
		background-color: var(--color-accent-primary-default);
		color: var(--color-base-white);

		&:hover {
			background-color: var(--color-accent-primary-light);
		}

		&:active {
			background-color: var(--color-accent-primary-dark);
		}

		&:disabled {
			background-color: var(--color-base-gray-400);
		}
	}

	&.outline {
		background-color: var(--color-base-white);
		color: var(--color-accent-primary-default);
		border: var(--color-accent-primary-default) px-to-rem(1px) solid;

		&:hover {
			background-color: var(--color-accent-primary-1000);
			color: var(--color-accent-primary-light);
			border-color: var(--color-accent-primary-light);
		}

		&:active {
			background-color: var(--color-accent-primary-1000);
			color: var(--color-accent-primary-dark);
			border: var(--color-accent-primary-dark) px-to-rem(2px) solid;
		}

		&:disabled {
			color: var(--color-base-gray-400);
			border-color: var(--color-base-gray-400);
		}
	}

	&:disabled {
		cursor: not-allowed;
	}

}

input:not([type]), // input defaults to "text" if not specified
input[type="text"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="url"],
input[type="tel"],
select {
	font-size: px-to-rem(16px); // override defaults
	height: px-to-rem(32px);
	line-height: 1.3;
	background-color: var(--color-base-gray-1000);
	border-radius: px-to-rem(8px);
	border: px-to-rem(1px) solid var(--color-base-gray-300);
	padding: px-to-rem(3px) px-to-rem(12px);
	box-sizing: border-box;
	transition: var(--default-transition);

	&::placeholder {
		color: var(--color-base-gray-200);
	}

	&:focus {
		background-color: var(--color-accent-primary-1000);
		border-color: var(--color-accent-primary-default);
		outline: none;
	}

	&:invalid {
		background-color: var(--color-tint-destructive-1000);
		border-color: var(--color-tint-destructive);
	}

	&:disabled {
		background-color: var(--color-base-gray-900);
		border-color: var(--color-base-gray-400);
		color: var(--color-base-black);
		opacity: 0.6; // note this will only work out on white backgrounds

		&:placeholder-shown {
			border-color: var(--color-base-gray-900);
			opacity: 0.5; // note this will only work out on white backgrounds
		}
	}
}

input[type="search"] {
	appearance: none;

	// remove cancel/clear button on Chromium
	&::-webkit-search-decoration,
	&::-webkit-search-cancel-button,
	&::-webkit-search-results-button,
	&::-webkit-search-results-decoration {
		display: none;
	}
}

input[type="radio"] {
	appearance: none;
	width: px-to-rem(20px);
	height: px-to-rem(20px);
	background-color: var(--color-base-white);
	border: px-to-rem(1px) solid $color-accent-primary-default;
	border-radius: px-to-rem(20px);
	place-content: center;
	display: grid;
	transition: background-color var(--default-transition);

	&::before {
		content: '';
		background-color: var(--color-base-white);
		height: px-to-rem(8px);
		width: px-to-rem(8px);
		border-radius: px-to-rem(8px);
		transform: scale(0);
		transition: transform var(--default-transition);
	}

	&:checked {
		background-color: var(--color-accent-primary-default);

		&::before {
			transform: scale(1);
		}
	}

	&:disabled {
		border-color: var(--color-base-gray-400);
		background-color: var(--color-base-gray-900);

		&::before {
			background-color: var(--color-base-gray-400);
		}

		&:checked {
			background-color: var(--color-base-gray-400);

			&::before {
				background-color: var(--color-base-gray-900);
			}
		}
	}
}

// todo select option styles
// https://moderncss.dev/custom-select-styles-with-pure-css/

// reset uls by default
ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

// Container for displaying a list of card-like items
// @deprecated - Implement directly in component going forward
.card-container {
	display: grid;
	justify-content: start;
	gap: 16px;

	// Government Profile Finances at a Glance style
	&.mobile-scroll-desktop-fit {
		// mobile 216px width in single row, scrolls horizontally
		//overflow: auto - will be handled in the overlay-scrollbars component
		grid-auto-flow: column;
		grid-auto-columns: px-to-rem(216px);
		grid-template-columns: unset;

		// Fit up to three items of equal width into the row, with a min-width of 216px before breaking onto a new row
		@media (min-width: $breakpoint-745) {
			overflow: visible;
			grid-auto-flow: unset;
			grid-template-columns: repeat(auto-fit, minmax(px-to-rem(216px), 1fr));
		}
	}

	// card-container layout
	&.columnar {
		grid-template-columns: minmax(0, 1fr);

		@media (min-width: $breakpoint-431) {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}

		// Explore cards style (default)
		&, &.compact {
			@media (min-width: $breakpoint-835) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
		}

		// Promoted Links style
		&.spacious {
			// override and stay at 2 columns at spacious layout
			@media (min-width: $breakpoint-835) {
				grid-template-columns: repeat(2, minmax(0, 1fr));
			}

			@media (min-width: $breakpoint-1201) {
				grid-template-columns: repeat(3, minmax(0, 1fr));
			}
		}
	}
}

// Draws a horizontal scroll shadow underneath this element when .visible is present. This sets the stacking order so
//  that the shadow draws over successor elements. Use with AppLayoutService to track successor element for scroll state.
.horizontal-shadow {
	position: relative;
	z-index: 1; // stack above the following element in the document so that the shadow draws over successor content
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.0);
	transition: box-shadow var(--default-transition);

	&.shadow-visible {
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
	}
}

// todo should this just be integrated into the button styles? It's just the color scheme that's different
.tag {
	box-sizing: border-box;
	cursor: pointer;
	width: fit-content;
	transition: all var(--default-transition);
	background-color: var(--color-accent-primary-1000);
	color: var(--color-accent-primary-default);
	border: var(--color-accent-primary-800) px-to-rem(.5px) solid;

	// start with small at first breakpoint
	font-weight: 700;
	font-size: px-to-rem(12px);
	border-radius: px-to-rem(4px);
	padding: 0 px-to-rem(4px);
	min-height: px-to-rem(16px);

	// large option at first breakpoint for gov profiles
	&.large {
		font-weight: 500;
		font-size: px-to-rem(16px);
		border-radius: px-to-rem(6px);
		padding: px-to-rem(2px) px-to-rem(10px);
		min-height: px-to-rem(32px);
	}

	// medium option at first breakpoint for dollars profile overview
	&.medium {
		font-weight: 500;
		font-size: px-to-rem(14px);
		border-radius: px-to-rem(6px);
		padding: px-to-rem(2px) px-to-rem(6px);
		min-height: px-to-rem(24px);
	}

	@media (min-width: $breakpoint-745) {
		&.small {
			font-weight: 700;
			font-size: px-to-rem(12px);
			border-radius: px-to-rem(4px);
			padding: 0 px-to-rem(4px);
			min-height: px-to-rem(16px);
		}
		& {
			font-weight: 500;
			font-size: px-to-rem(14px);
			border-radius: px-to-rem(6px);
			padding: px-to-rem(2px) px-to-rem(6px);
			min-height: px-to-rem(24px);
		}
	}

	&:enabled:hover {
		background-color: var(--color-accent-primary-800);
		color: var(--color-accent-primary-light);
		border-color: var(--color-accent-primary-light);
	}

	&:enabled:active {
		background-color: var(--color-accent-primary-dark);
		color: var(--color-accent-primary-1000);
	}

	&:disabled {
		color: var(--color-base-white);
		background-color: var(--color-base-gray-500);
		border-color: var(--color-base-gray-500);
		cursor: not-allowed;
	}

}




// region layouts

hr {
	border: 0.5px solid var(--color-base-gray-500);
	width: 100%;
	margin: 0;
}

//todo - handle size on small mobile
.hint {
	color: var(--color-base-gray-300);

	&:hover {
		color: var(--color-accent-primary-light);
	}

	&:active {
		color: var(--color-accent-primary-dark);
	}

	&.aligned {
		align-self: flex-start;
	}
}

// Applies padding to content area (including header)
.content-gutter {

	// .compact is default
	&, &.compact {
		padding-left: 16px;
		padding-right: 16px;

		@media (min-width: $breakpoint-431) {
			padding-left: 32px;
			padding-right: 32px;
		}

		@media (min-width: $breakpoint-745) {
			padding-left: 40px;
			padding-right: 40px;
		}

		@media (min-width: $breakpoint-1201) {
			padding-left: 56px;
			padding-right: 56px;
		}
	}

	&.spacious {
		padding-left: 16px;
		padding-right: 16px;

		@media (min-width: $breakpoint-431) {
			padding-left: 32px;
			padding-right: 32px;
		}

		@media (min-width: $breakpoint-745) {
			padding-left: 48px;
			padding-right: 48px;
		}

		@media (min-width: $breakpoint-835) {
			padding-left: 64px;
			padding-right: 64px;
		}

		@media (min-width: $breakpoint-1201) {
			padding-left: 80px;
			padding-right: 80px;
		}
	}

	// second .clipped allows clipping on individual elements inside .content-gutter
	&.clipped, .clipped {
		@media (min-width: $breakpoint-745) {
			max-width: px-to-rem(1063px);
		}
	}
}

// Single-column vertical layout
// .vertical-content for non-profile pages like extracts, etc.
// .vertical-profile for profile pages, etc.
.vertical-content, .vertical-profile {
	display: flex;
	flex-direction: column;

	&.vertical-content {
		gap: px-to-rem(16px);

		@media (min-height: $breakpoint-745) {
			gap: px-to-rem(24px);
		}

		@media (min-height: $breakpoint-981) {
			gap: px-to-rem(40px);
		}

		hr {
			margin: px-to-rem(4px) 0;
		}
	}

	padding-bottom: 96px;

	> overlay-scrollbars {
		padding-bottom: 96px;
	}
}

// endregion layouts

// region app-map
// content gets projected outside the Map component
app-map {
	.controls {
		display: flex;
		flex-direction: column;
		gap: px-to-rem(16px);
		align-items: flex-start;

		// insert svg background here for target icon to get currentColor applied
	}

	.marker-label {
		// font properties don't do anything
		// text-shadow: var(--color-base-white) 0 0 10px;
	}
}

// endregion

// manipulate labels here to end-run encapsulation
app-government-search, app-financial-health-profile {
	.form-field {
		//@include breakpoint($breakpoint-990, below) {
		//	flex-direction: column;
		//	align-items: flex-start;
		//}
		label {
			@include breakpoint($breakpoint-990, below) {
				display: none;
			}
		}

	}
}

// styles for ag grid
ag-grid-angular {
	height: 100%;
	width: 100%;
	--ag-odd-row-background-color: var(--color-base-gray-1000);

	// header styles
	// set for header icon
	--ag-icon-font-family: agGridAlpine;

	.ag-header {
		// start with no header
		display: none;
		border: 0 solid var(--color-base-gray-500);
		background-color: var(--color-base-gray-1000);

		@media (min-width: $breakpoint-555) {
			// show headers
			display: initial;
		}
	}

	.ag-cell-label-container {
		padding-top: px-to-rem(8px);
		padding-bottom: px-to-rem(8px);
	}

	// set for the first header column
	.first-header {
		padding-left: px-to-rem(16px);
	}

	// dollar and percentage headers
	.dollar-header {
		.ag-header-cell-label {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-right: px-to-rem(16px);
		}
	}

	.ag-header-cell {
		color: var(--color-base-black);
		font-size: 14px;
		min-height: 0;
	}

	// cell styles
	.custom-cell-name {
		padding: px-to-rem(16px);
	}

	.grid-cell {
		display: flex;
		align-items: center;
	}

	.grid-cell-dollars {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: px-to-rem(16px);
	}

	// todo ask about making these universal
	.green {
		color: var(--color-tint-success);
	}

	.purple {
		color: var(--color-tint-purple);
	}

	.blue {
		color: var(--color-accent-primary-default)
	}

	.grey {
		color: var(--color-base-gray-200);
	}
}

app-population-records {
	.readonly {
		background: rgb(244, 246, 242);
	}
}

/*  ------- Styles for Badge-On-Element ------- */
.badge-container {
	position: relative;
}

.badge {
	border-radius: 50%;
	color: #fff;
	text-align: center;
	background: var(--color-accent-primary-default);
	width: 20px;
	height: 20px;

	position: absolute;
	left: -4px;
	top: -4px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%; /* 15.6px */
}

// custom table styling goes here since encapsulation prevents application otherwise
.os-table {
	--os-handle-bg: var(--color-base-gray-500);
	--os-handle-bg-hover: rgba(0, 0, 0, 0.55); // default black theme
	--os-handle-bg-active: rgba(0, 0, 0, 0.66); // default black theme
	--os-track-bg: var(--color-base-gray-900);
	--os-track-bg-hover: var(--color-base-gray-900);
	--os-track-bg-active: var(--color-base-gray-900);
	--os-size: 0.5rem;
	--os-handle-border-radius: 0.5rem;
}
